import app from "firebase/app";
import "firebase/auth";
import React from "react";
export const FirebaseContext = React.createContext({});

const firebaseConfig = {
  apiKey: "AIzaSyAgBTDdtSX4H3Inl5tQyg2zXFPte0OeB_8",
  authDomain: "rain-portal-a1c72.firebaseapp.com",
  databaseURL: "https://rain-portal-a1c72.firebaseio.com",
  projectId: "rain-portal-a1c72",
  storageBucket: "rain-portal-a1c72.appspot.com",
  messagingSenderId: "436381311786",
  appId: "1:436381311786:web:cbea27392d12eb15f43453",
  measurementId: "G-SNZGBSCVL4",
};

export class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }

    this.auth = app.auth();
  }

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  signOut = () => this.auth.signOut();
  passwordReset = (email) => this.auth.sendPasswordResetEmail(email);
  passwordUpdate = (password) => this.auth.currentUser.updatePassword(password);
}
