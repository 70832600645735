import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Panel from "../components/Panel";
import { FirebaseContext } from "../context/Firebase";
import { UserContext } from "../context/User";
export default function Login() {
  const firebaseTxt = useContext(FirebaseContext);
  const userTxt = useContext(UserContext);

  // state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logoutMessage, setLogoutMessage] = useState(false);
  const [loginError, setLoginError] = useState(false);

  let history = useHistory();
  const clear = () => {
    setEmail("");
    setPassword("");
    setLoginError(false);
    setLogoutMessage(false);
  };

  const onSubmit = (event) => {
    firebaseTxt
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        clear();
        history.replace("/projects");
      })
      .catch((error) => {
        // TODO: show different error messages?
        setLoginError(true);
        setPassword("");
      });
    event.preventDefault();
  };

  return (
    <div>
      <div className="row wrapper">
        <div className="col s1 leftWrapper">{/* {{>page/logo}} */}</div>
        <div className="col s11 rightWrapper">
          <div className="loginArea">
            <form onSubmit={onSubmit}>
              <div className="login-div layout-column">
                <Panel
                  type="success"
                  title="Success"
                  description="You have successfully logged out."
                  show={logoutMessage}
                ></Panel>

                <Panel
                  type="error"
                  title="Error"
                  description="There was an error logging in."
                  show={loginError}
                ></Panel>

                <div className="layout-column flex-100">
                  <span className="login-labels">EMAIL ADDRESS </span>
                  <input
                    className="login-inputs"
                    placeholder="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required={true}
                  />
                  <div className="password-options layout-row layout-align-space-between">
                    <span className="login-labels">PASSWORD </span>
                  </div>
                  <input
                    className="login-inputs"
                    placeholder="*****"
                    name="password"
                    type="password"
                    value={password}
                    required={true}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <div className="submit-button">
                    <input
                      className="btn sign-in-button"
                      type="submit"
                      value="Sign In"
                    />
                  </div>
                  <div className="signup-div">
                    <span>Don't have an account?</span>&nbsp;
                    <a href="#">Sign up</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
