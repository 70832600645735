import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../context/Firebase";
import { UserContext } from "../context/User";
export default function GlobalTop(props) {
  const userTxt = useContext(UserContext);
  const firebaseTxt = useContext(FirebaseContext);
  let history = useHistory();
  const logout = () => {
    firebaseTxt.auth.signOut().then(() => {
      history.push("/");
    });
  };

  return (
    <div className="globalTop">
      <div className="col s1 logo-div">
        <img
          className="company-logo"
          src="/assets/images/rain-logo.png"
          alt="Company-Logo"
        />
      </div>

      <div className="col s11 logged-in-user-div">
        <div className="row">
          <div className="col s4">
            <h6>PORTAL</h6>
          </div>
          <div className="col s8">
            <div className="userInfo">
              <div className="name">
                {userTxt.data.info.displayName
                  ? userTxt.data.info.displayName
                  : userTxt.data.info.email}
              </div>
              <div className="logout" onClick={logout}>
                <a href="#">Logout</a>
              </div>
            </div>
            <div className="userImg">
              <img src="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
