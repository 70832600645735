import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import { Firebase, FirebaseContext } from "./context/Firebase";
import { User, UserContext } from "./context/User";
import Login from "./pages/Login";
import Projects from "./pages/Project/Projects";

export default function App() {
  const [userTxt, setUserTxt] = useState(new User());
  const [firebaseTxt] = useState(new Firebase());

  useEffect(() => {
    const cloneUserTxt = new User();
    cloneUserTxt.data = { ...userTxt.data };

    if (cloneUserTxt.signature() !== userTxt.signature()) {
      setUserTxt(cloneUserTxt);
    }

    firebaseTxt.auth.onAuthStateChanged(function (user) {
      const cloneUserTxt = new User();
      cloneUserTxt.data = { ...userTxt.data };
      cloneUserTxt.setLoading(false);
      if (user) {
        cloneUserTxt.setInfo(user);
      } else {
        cloneUserTxt.clear();
      }
      cloneUserTxt.setLoading(false);

      if (cloneUserTxt.signature() !== userTxt.signature()) {
        setUserTxt(cloneUserTxt);
      }
    });
  });
  return userTxt.data.loading ? (
    <h2>Loading...</h2>
  ) : (
    <FirebaseContext.Provider value={firebaseTxt}>
      <UserContext.Provider value={userTxt}>
        <Router>
          <Switch>
            <PublicRoute
              exact
              path="/"
              loading={userTxt.data.loading}
              authenticated={userTxt.isAuthenticated()}
              component={Login}
            ></PublicRoute>

            <PublicRoute
              exact
              path="/login"
              loading={userTxt.data.loading}
              authenticated={userTxt.isAuthenticated()}
              component={Login}
            ></PublicRoute>
            <PrivateRoute
              exact
              path="/projects"
              loading={userTxt.data.loading}
              authenticated={userTxt.isAuthenticated()}
              component={Projects}
            ></PrivateRoute>
            {/* <PrivateRoute
            path="/chat"
            authenticated={this.state.authenticated}
            component={Chat}
          ></PrivateRoute>
          <PublicRoute
            path="/signup"
            authenticated={this.state.authenticated}
            component={Signup}
          ></PublicRoute>
          <PublicRoute
            path="/login"
            authenticated={this.state.authenticated}
            component={Login}
          ></PublicRoute> */}
          </Switch>
        </Router>
      </UserContext.Provider>
    </FirebaseContext.Provider>
  );
}

function PrivateRoute({
  component: Component,
  authenticated,
  loading,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/projects", state: { from: props.location } }}
          />
          // <Component {...props} />
        )
      }
    />
  );
}
