import React from "react";
export const defaultUser = {
  loading: true,
  info: {},
};

export class User {
  constructor() {
    this.clear();
  }

  setInfo(info) {
    const { displayName, providerId, uid, photoURL, email, phoneNumber } = info;

    this.data.info = {
      displayName,
      providerId,
      uid,
      photoURL,
      email,
      phoneNumber,
    };
  }

  // get info() {
  //   return this.data.info;
  // }

  isAuthenticated() {
    return (
      this.data.info && this.data.info.uid && this.data.info.uid.length > 1
    );
  }

  setLoading(loading) {
    this.data.loading = loading;
  }
  // get loading() {
  //   return this.data.loading;
  // }

  // get isAuthenticated() {
  //   return this.data.info.uid;
  // }

  clear() {
    this.data = { ...defaultUser };
  }

  signature() {
    return JSON.stringify(this);
  }
}
export const UserContext = React.createContext(defaultUser);
