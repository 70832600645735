import React, { useContext, useState } from "react";
import GlobalTop from "../../components/GlobalTop";
import PageHeader from "../../components/PageHeader";
import ProjectsLeft from "../../components/ProjectsLeft";
import { FirebaseContext } from "../../context/Firebase";
import ProjectListItem from "./ProjectListItem";

export default function Login() {
  const [projects, setProjects] = useState([
    {
      fields: {
        Abbr: "AT",
        uid: "autotraderID",
        ProjectName: "Autotrader",
        Status: "Active",
      },
    },
  ]);
  const firebaseTxt = useContext(FirebaseContext);
  return (
    <div>
      <GlobalTop></GlobalTop>
      <div className="wrapper">
        <ProjectsLeft></ProjectsLeft>

        <div className="col s11 rightWrapper">
          <div className="row">
            <div className="col s12">
              {/* <!-- page head --> */}
              <div className="pageHeader">
                <PageHeader></PageHeader>
              </div>

              {/* <!-- page body --> */}
              <div className="pageBody">
                <div className="row projects">
                  <div className="listHeader">
                    <div className="col s6 columnTitle">
                      <h5>Projects</h5>
                    </div>
                    <div className="col s3 columnStatus">
                      <h5>Status</h5>
                    </div>
                    <div className="col s3 columnAction">
                      <h5>Actions</h5>
                    </div>
                  </div>
                  {projects.map((project) => {
                    return (
                      <ProjectListItem project={project}></ProjectListItem>
                    );
                  })}

                  {/* <!-- / projects --> */}
                </div>

                {/* <!-- / page body --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
