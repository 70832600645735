import React from "react";

export default function ProjectListItem({ project }) {
  return (
    <div className="listItem">
      <div className="col s6 columnTitle">
        <h6>
          <a href="/projects/{project.fields.Abbr}/{project.fields.UID}">
            <span className="abbr">{project.fields.Abbr}</span> -{" "}
            {project.fields.ProjectName}
          </a>
        </h6>
      </div>
      <div className="col s3 columnStatus">
        <div className="statusFlag">
          <span className="status {{project.fields.Status}}">
            {project.fields.Status}
          </span>
        </div>
      </div>
      <div className="col s3 columnAction"></div>
    </div>
  );
}
