import React from "react";
export default function ProjectsLeft() {
  return (
    <div class="col s1 leftWrapper">
      <div class="projectNav">
        <a href="">
          <i class="material-icons md-24">tune</i>
        </a>
      </div>
    </div>
  );
}
