import React from "react";
export default function Panel(props) {
  const t = props.type ? props.type : "success";
  const classN = `panel ${t}-panel`;
  const title = props.title;
  const description = props.description;
  const show = props.show;

  return show ? (
    <div className={classN}>
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  ) : null;
}
